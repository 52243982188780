//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-112:_5648,_7568,_3676,_8692,_7560,_8728,_5628,_8376;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-112')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-112', "_5648,_7568,_3676,_8692,_7560,_8728,_5628,_8376");
        }
      }catch (ex) {
        console.error(ex);
      }